header
	// background-color: rgba(10, 9, 10, 0.4)
	transition: all ease 0.50s
	position: absolute
	top: 0
	width: 100%
	background-color: transperent
	z-index: 4
	.top-menu
		float: right
	.logo
		display: inline-block
		padding: 29px 0 19px 0
		position: relative
		img
			height: 47px
			transition: all ease 0.50s
		.logo-dark
			display: none
	/*Dark logo*/
	.dark-logo
		.logo-white
			display: none
		.logo-dark
			display: block
form
	.btn
		margin-top: 25px
