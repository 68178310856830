/* You can add global styles to this file, and also import other style files */
@import "./sass/libs.sass"
@import "./sass/main.sass"

/*Survey form adjustments */

.multi-select
    .form-group
        background-color: #504645
        padding-top: 9px

.form-group
    label
        color: #fff
        padding-left: 20px

form 
    select
        background-color: #504645
        border: 0
        

input[type="checkbox" i]
    background-color: #b59673

select:-internal-list-box option:focus
    background-color: #c69c6d

.invalid-feedback
    margin-top: -30px
    margin-bottom: 5px
    color: #ff7600



/*End Survey form adjustments */

/*Add Agency Adjustments */


/*End Agency Adjustments */

/*Add support to header for login button aligned right

header .container
    display: flex

header .logo
    flex-grow: 1

header .app-login-button
    padding-top: 29px

.error-notification-overlay
    background-color: red
    color: white

.success-notification-overlay
    background-color: green
    color: white

@media only screen and (max-width: 992px)
    header .app-login-button
        padding: 12px 0 9px 0

/*End header update for login */