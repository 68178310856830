@import "../../../node_modules/bourbon/app/assets/stylesheets/_bourbon.scss"

$accent-font: 'Poppins', sans-serif 

// // blue
//$accent-color: #3ba5e3
//$dark-accent: #424e5c

// // green 
//$accent-color: #2ecc71
//$dark-accent: #3b3b33

// // lime
//$accent-color: #8eda5c
//$dark-accent: #3b3b33

// // green1
//$accent-color: #73ae20
//$dark-accent: #3b3b33

// // yellow 
//$accent-color: #feb500
//$dark-accent: #2e240a

// // yellow2
//$accent-color: #f1c40f
//$dark-accent: #2e240a

// // red
//$accent-color: #f63145
//$dark-accent: #120506

// // red2
//$accent-color: #ff5454
//$dark-accent: #120606

// // violet
//$accent-color: #c472d6
//$dark-accent: #1d1821

// // violetred
//$accent-color: #7877c1
//$dark-accent: #1d1821
 
// // accent-two
//$accent-color: #F97C46
//$dark-accent: #5f4337

$accent-color: #c69c6d
$dark-accent: #504645

$font-default: #555555
$font-dark: #606060
$heading-dark: #424242
$white: #fff

/*bg mask colors*/
$bg-mask-blue: #3047d3
$bg-mask-white: #fff
$bg-mask-black: #000
$bg-mask-yellow: #d38426
$bg-mask-red: #d34730
$bg-mask-darkness: #233239
$bg-mask-darkmilness: #424e5c
