.slider
	position: relative
	overflow: hidden
	padding: 0
	.buttons-section
		margin-top: 45px
	.dots-control-carousel
		position: absolute
		z-index: 3
		margin-top: 0
		bottom: 30px
		right: 30px
		li
			margin-right: 12px
			button
				font-size: 0px
				padding: 0
				height: 15px
				width: 15px
				border-radius: 50%
				outline: none
				opacity: 0.5
				background-color: transparent
				border: 3px solid $white
				display: block
		.slick-active	
			button
				position: relative
				top: -1px
				width: 15px
				height: 15px
				opacity: 1
				border: none
				background: $white
.tlt
	span
		color: $white
.letter-s-8
	letter-spacing: 8px
.full-slider
	.slide
		.img-slide
			margin-bottom: 30px
/*Customize for slids*/
.full-slider,.wrap-header
	.slide
		.head-desc
			.description-slide
				margin-top: 0
		/*small text*/
		.small-text
			.description-slide
				font-weight: 300
				font-size: 14px
			.heading-title-big
				font-size: 45px
		.black-text
			.description-slide, .heading-title-big
				color: #1d1c1c

