
@import "../../node_modules/bourbon/app/assets/stylesheets/_bourbon.scss"					//Bourbon mixin library for Sass
@import "components/_vars.sass"		//Variables
@import "components/_mixins.sass"	//Some mixins
@import "components/_fonts.sass"	//Fonts

body
	font-size: 16px
	min-width: 320px
	font-weight: 300
	position: relative
	line-height: 1.6
	font-family: $accent-font
	overflow-x: hidden
	-webkit-font-smoothing: antialiased
	text-rendering: optimizeLegibility
	@include scrollbar()

.mfp-wrap
	@include scrollbar()
html
	font-size: inherit
	margin-right: 0!important
	
iframe
	border: none
	display: block
/*Page loader*/

.pace
	pointer-events: none
	user-select: none
.pace-inactive
	display: none
.pace
	.pace-progress
		background: $accent-color
		position: fixed
		z-index: 2000
		top: 0
		right: 100%
		width: 100%
		height: 2px
	.pace-progress-inner
		display: block
		position: absolute
		right: 0px
		width: 100px
		height: 100%
		box-shadow: 0 0 10px $accent-color, 0 0 5px $accent-color
		opacity: 1.0
		transform: rotate(3deg) translate(0px, -4px)
	.pace-activity
		display: block
		position: fixed
		z-index: 2000
		top: 15px
		right: 15px
		width: 14px
		height: 14px
		border: solid 2px transparent
		border-top-color: $accent-color
		border-left-color: $accent-color
		border-radius: 10px
		animation: pace-spinner 400ms linear infinite
@keyframes pace-spinner
	0%
		transform: rotate(0deg)
		transform: rotate(0deg)
	100%
		transform: rotate(360deg)
		transform: rotate(360deg)

.hidden
	display: none
i
	line-height: inherit!important
p
	margin-top: 20px
	margin-bottom: 0
@import "components/_loader.scss"
// Components
@import "components/_components.sass"		// Compononets

@import "pages/_pages.sass"					// Pages
@import "components/blog/_blog.sass"		// Blog
@import "components/head/_header.sass"		// Header
@import "components/head/_sliders.sass"		// Sliders
@import "_sections.sass"					// Sections
@import "components/_animation.sass"		// Animation

//Media
@import "components/_media.sass"			// Media
