// Vertical align 
@mixin vertical-align($position: relative)
	position: $position
	top: 50%
	transform: translateY(-50%)
@mixin scrollbar
	&::-webkit-scrollbar-track
		background-color: #fff
	&::-webkit-scrollbar
		width: 5px
		background-color: #fff
	&::-webkit-scrollbar-thumb
		background-color: $accent-color
=mt($time)
	transition: all $time ease