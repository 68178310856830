
/* Preloader */
#preloader {
  top: 0;  left: 0;  right: 0;  bottom: 0;
  position: absolute;
  background-color: #fff;
  z-index: 5;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

#status {
  position: absolute;
  left: 47%;
  top: 50%;
  margin: auto;
  width: 49px;
  height: 49px;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  background: $accent-color;
  animation: 1.15s infinite ease-in-out;
  -webkit-animation: 1.15s infinite ease-in-out;
  -moz-animation: 1.15s infinite ease-in-out;
  &:before, &:after {
    animation: 1.15s infinite ease-in-out;
    -webkit-animation: 1.15s infinite ease-in-out;
    -moz-animation: 1.15s infinite ease-in-out;
  }
  &:before, &:after {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
  }
  animation-name: loader;
  -webkit-animation-name: loader;
  -moz-animation-name: loader;
}

@keyframes loader {
  from {
    transform: scale(0);
    opacity: 1;
  }

  to {
    transform: scale(1);
    opacity: 0.5;
  }
}


@-ms-keyframes loader {
  from {
    -ms-transform: scale(0);
    opacity: 1;
  }

  to {
    -ms-transform: scale(1);
    opacity: 0.5;
  }
}


@-webkit-keyframes loader {
  from {
    -webkit-transform: scale(0);
    opacity: 1;
  }

  to {
    -webkit-transform: scale(1);
    opacity: 0.5;
  }
}


@-moz-keyframes loader {
  from {
    -moz-transform: scale(0);
    opacity: 1;
  }

  to {
    -moz-transform: scale(1);
    opacity: 0.5;
  }
}