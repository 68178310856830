.ui-helper-hidden
  display: none

.ui-helper-hidden-accessible
  border: 0
  clip: rect(0 0 0 0)
  height: 1px
  margin: -1px
  overflow: hidden
  padding: 0
  position: absolute
  width: 1px

.ui-helper-reset
  margin: 0
  padding: 0
  border: 0
  outline: 0
  line-height: 1.3
  text-decoration: none
  font-size: 100%
  list-style: none

.ui-helper-clearfix
  &:before
    content: ""
    display: table
    border-collapse: collapse
  &:after
    content: ""
    display: table
    border-collapse: collapse
    clear: both

.ui-helper-zfix
  width: 100%
  height: 100%
  top: 0
  left: 0
  position: absolute
  opacity: 0
  filter: Alpha(Opacity = 0)


.ui-front
  z-index: 100



.ui-state-disabled
  cursor: default !important
  pointer-events: none



.ui-icon
  display: inline-block
  vertical-align: middle
  margin-top: -.25em
  position: relative
  text-indent: -99999px
  overflow: hidden
  background-repeat: no-repeat

.ui-widget-icon-block
  left: 50%
  margin-left: -8px
  display: block



.ui-widget-overlay
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%

.ui-accordion
  .ui-accordion-header
    display: block
    cursor: pointer
    position: relative
    margin: 2px 0 0 0
    padding: .5em .5em .5em .7em
    font-size: 100%
  .ui-accordion-content
    padding: 1em 2.2em
    border-top: 0
    overflow: auto

.ui-tabs
  position: relative
  padding: .2em
  .ui-tabs-nav
    margin: 0
    padding: .2em .2em 0
    li
      list-style: none
      float: left
      position: relative
      top: 0
      margin: 1px .2em 0 0
      border-bottom-width: 0
      padding: 0
      white-space: nowrap
    .ui-tabs-anchor
      float: left
      padding: .5em 1em
      text-decoration: none
    li
      &.ui-tabs-active
        margin-bottom: -1px
        padding-bottom: 1px
        .ui-tabs-anchor
          cursor: text
      &.ui-state-disabled .ui-tabs-anchor, &.ui-tabs-loading .ui-tabs-anchor
        cursor: text

.ui-tabs-collapsible .ui-tabs-nav li.ui-tabs-active .ui-tabs-anchor
  cursor: pointer

.ui-tabs .ui-tabs-panel
  display: block
  border-width: 0
  padding: 1em 1.4em
  background: none