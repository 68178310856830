.display-page
	padding-top: 130px
	padding-bottom: 90px
	color: $white
	h1
		font-size: 37px
		font-weight: 600
		text-transform: uppercase
		&:after
			content: ""
			display: block
			height: 2px
			width: 60px
			background-color: $accent-color
			margin-top: 8px
			margin-bottom: 15px
		span
			color: $accent-color
	p
		font-size: 14px
.unity
	padding-bottom: 90px
	.description
		margin-bottom: 120px
//Single work
.head-project
	padding-top: 80px
	padding-bottom: 80px
	.title-project
		margin-top: 0
.content-project
	p
		font-size: 14px
		color: #606060
	h2, h3
		font-weight: 600
		text-transform: uppercase
		font-size: 20px
		letter-spacing: 1px
		margin-bottom: 0
		span
			color: $accent-color
		&:after
			content: ""
			display: block
			height: 2px
			width: 40px
			background-color: $accent-color
			margin-top: 8px
			margin-bottom: 15px
	.list-project
		padding-left: 0
		li
			list-style: none
			font-size: 14px
			margin-bottom: 5px
			color: #585858
			letter-spacing: 0.5px
			&:last-child
				margin-bottom: 0
			b
				font-weight: 600
			a
				color: $accent-color
				i
					margin-left: 6px
					position: relative
					top: 1px
.single-project
	.about-us
		padding-top: 0
	.full-slider
		.slide
			.small-text
				.heading-title-big
					font-size: 65px
.gallery-slide
	overflow: hidden
	position: relative

/*Service single*/
.content-service-single
	padding-bottom: 30px
	h1,h2,h3,h4,h5
		margin-top: 0
		&:after
			content: ""
			display: block
			height: 2px
			width: 40px
			background-color: $accent-color
			margin-top: 8px
			margin-bottom: 15px
	ul.list
		margin-top: 30px
.service-about
	ul.list
		margin-top: 15px
